import React, { useState } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormHelperText,
  Textarea,
  Button,
  SimpleGrid,
} from '@chakra-ui/react'

import * as Yup from 'yup'

import { useFormik } from 'formik'
// eslint-disable-next-line file-extension-in-import-ts/file-extension-in-import-ts
import Link from './Link'

const ContactSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Der Vorname ist zu kurz')
    .max(100, 'Der Vorname ist zu lang')
    .required('Dieses Feld braucht einen gültigen Inhalt.'),
  nachname: Yup.string()
    .min(2, 'Der Vorname ist zu kurz')
    .max(100, 'Der Vorname ist zu lang')
    .required('Dieses Feld braucht einen gültigen Inhalt.'),
  email: Yup.string()
    .email('Das ist keine gültige E-Mail')
    .required('Dieses Feld braucht einen gültigen Inhalt.'),
  telefon: Yup.string(),
  datenschutzAkzeptiert: Yup.bool().oneOf(
    [true],
    'Die Datenschutzvereinbarung muss akzeptiert werden.'
  ),
  nachricht: Yup.string()
    .min(50, 'Ihre Nachricht ist zu kurz.')
    .max(
      1000,
      'Der Text ist leider zu lang. Bitte fassen Sie sich etwas kürzer'
    )
    .required('Dieses Feld braucht einen gültigen Inhalt.'),
})

type ContactFormProps = BoxProps

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

const encode2 = (object) => {
  let encodedString = ''
  for (const prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (encodedString.length > 0) {
        encodedString += '&'
      }
      encodedString += encodeURI(`${prop}=${object[prop]}`)
    }
  }
  return encodedString
}

export default ({}: ContactFormProps) => {
  const [wasSubmitted, setwasSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: {
      vorname: '',
      nachname: '',
      email: '',
      telefon: '',
      nachricht: '',
      datenschutzAkzeptiert: false,
    },
    validationSchema: ContactSchema,
    onSubmit: (values, actions) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode2({ 'form-name': 'contact', ...values }),
      })
        .then(() => {
          alert('Ihre Nachricht wurde erfolgreich verschickt!')
          actions.resetForm()
        })
        .catch(() => {
          alert('Leider gab es einen Fehler beim Versenden Ihrer Nachricht.')
        })
        .finally(() => actions.setSubmitting(false))
    },
  })

  return (
    <Box
      as="form"
      name="contact"
      data-netlify="true"
      onSubmit={() => {
        formik.handleSubmit()
      }}
    >
      <SimpleGrid
        columns={[1, null, 2]}
        spacing="10"
      >
        <Box>
          <FormControl
            isRequired
            mb="4"
            isInvalid={
              !!(
                wasSubmitted &&
                formik.errors.vorname &&
                formik.touched.vorname
              )
            }
          >
            <FormLabel>Vorname</FormLabel>
            <Input
              id="vorname"
              type="text"
              {...formik.getFieldProps('vorname')}
            />
            <FormErrorMessage>{formik.errors.vorname}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            mb="4"
            isInvalid={
              !!(
                wasSubmitted &&
                formik.errors.nachname &&
                formik.touched.nachname
              )
            }
          >
            <FormLabel>Nachname</FormLabel>
            <Input
              id="nachname"
              type="text"
              {...formik.getFieldProps('nachname')}
            />
            <FormErrorMessage>{formik.errors.nachname}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            mb="4"
            isInvalid={
              !!(wasSubmitted && formik.errors.email && formik.touched.email)
            }
          >
            <FormLabel>E-Mail Adresse</FormLabel>
            <Input
              id="email"
              type="email"
              {...formik.getFieldProps('email')}
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            mb="4"
            isInvalid={
              !!(
                wasSubmitted &&
                formik.errors.telefon &&
                formik.touched.telefon
              )
            }
          >
            <FormLabel>Telefonnummer</FormLabel>
            <Input
              id="telefon"
              type="telephone"
              {...formik.getFieldProps('telefon')}
            />
            <FormHelperText>Wir rufen Sie auch gerne zurück.</FormHelperText>
            <FormErrorMessage>{formik.errors.telefon}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box>
          <FormControl
            isRequired
            mb="4"
            isInvalid={
              !!(
                wasSubmitted &&
                formik.errors.nachricht &&
                formik.touched.nachricht
              )
            }
          >
            <FormLabel>Ihre Nachricht</FormLabel>
            <Textarea
              id="nachricht"
              rows={8}
              placeholder="Liebes Praxis-Team, ..."
              {...formik.getFieldProps('nachricht')}
            />
            <FormErrorMessage>{formik.errors.nachricht}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            mb="4"
            isInvalid={!!(wasSubmitted && formik.errors.datenschutzAkzeptiert)}
          >
            <Checkbox
              name="datenschutzAkzeptiert"
              isChecked={formik.values.datenschutzAkzeptiert}
              onChange={(e) =>
                formik.setFieldValue(
                  'datenschutzAkzeptiert',
                  e.currentTarget.checked
                )
              }
            >
              Ich habe die <Link href="/datenschutz">Datenschutzerklärung</Link>{' '}
              zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten
              zur Beantwortung meiner Anfrage elektronisch erhoben und
              gespeichert werden.
            </Checkbox>

            <FormErrorMessage>
              {formik.errors.datenschutzAkzeptiert}
            </FormErrorMessage>
          </FormControl>

          <Flex justifyContent="flex-end">
            <Button
              onClick={() => {
                setwasSubmitted(true)
              }}
              type="submit"
            >
              Abschicken
            </Button>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  )
}
