import React from 'react'
import { Box, Heading, BoxProps } from '@chakra-ui/react'

interface PresetProps extends BoxProps {
  children: React.ReactNode
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export default ({ children, as = 'h2' }: PresetProps) => {
  return (
    <Heading as={as} textStyle={as} mb="8">
      {children}
    </Heading>
  )
}
