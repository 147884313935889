import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Box, Flex, Center, BoxProps } from '@chakra-ui/react'
import Img from 'gatsby-image'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { AnimatePresence } from 'framer-motion'
import MotionBox from './MotionBox'

interface PresetProps extends BoxProps {
  images: GatsbyTypes.GalleryImagesQuery['galleryImages']['edges']
  selectedImage: number
  isVisible: boolean
  maxWidth?: string
  maxHeight?: string
  onClose?: () => void
}

export default ({
  images,
  selectedImage,
  maxWidth = '1000px',
  maxHeight = '800px',
  isVisible = false,
  onClose,
}: PresetProps) => {
  const [index, setIndex] = useState(selectedImage)
  const [areControlsHidden, hideControls] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const backDropRef = useRef<React.ReactHTMLElement>(null)

  const setTimer = useCallback(() => {
    hideControls(false)

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      hideControls(true)
    }, 600)
  }, [])

  const showControlsOnMove = useCallback(() => {
    setTimer()
  }, [setTimer])

  const prevImage = useCallback(() => {
    setIndex((oldI) => {
      let next = oldI - 1

      if (next < 0) {
        next = images.length - 1
      }
      return next
    })
  }, [images.length])

  const nextImage = useCallback(() => {
    setIndex((oldI) => {
      let next = oldI + 1
      if (next > images.length - 1) {
        next = 0
      }
      return next
    })
  }, [images.length])

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      setTimer()

      if (e.key === 'ArrowLeft') {
        prevImage()
        e.preventDefault()
      } else if (e.key === 'ArrowRight') {
        nextImage()
        e.preventDefault()
      }
    },
    [prevImage, nextImage, setTimer]
  )

  useEffect(() => {
    setTimer()
    setIndex(selectedImage)
    document.addEventListener('mousemove', showControlsOnMove)
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('mousemove', showControlsOnMove)
      document.removeEventListener('keydown', handleKeyPress)
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [handleKeyPress, selectedImage, setTimer, showControlsOnMove])

  const handleClickOnBackDrop = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.target === backDropRef.current) {
      if (onClose) {
        onClose(e)
      }
    }
    e.preventDefault()
  }

  const image = images[index]

  return (
    <AnimatePresence>
      {isVisible && image && (
        <MotionBox
          top="0"
          left="0"
          right="0"
          bottom="0"
          pos="fixed"
          background="rgba(0,0,0,.73)"
          zIndex="1000"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={handleClickOnBackDrop}
          ref={backDropRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Flex
            flexDir="column"
            width="80vw"
            height="80vh"
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          >
            <Box flex="1" overflow="hidden" pos="relative" alignItems="center">
              <Img
                style={{
                  // width: '100%',
                  // height: '100%',
                  objectFit: 'contain',
                  objectPosition: '50% 50%',
                }}
                // imgStyle={{
                //   objectFit: 'contain',
                //   objectPosition: '50% 50%',
                // }}
                fluid={image.node.childImageSharp?.fluid}
              />
              <MotionBox
                animate={{ opacity: areControlsHidden ? 0 : 1 }}
                pos="absolute"
                width="100px"
                left="0"
                top="0"
                bottom="0"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={prevImage}
              >
                <Box as={IoIosArrowBack} />
              </MotionBox>
              <MotionBox
                animate={{ opacity: areControlsHidden ? 0 : 1 }}
                pos="absolute"
                width="100px"
                right="0"
                top="0"
                bottom="0"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={nextImage}
              >
                <Box as={IoIosArrowForward} />
              </MotionBox>
            </Box>
            <Flex
              display="flex"
              justifyContent="center"
              alignItems="center"
              h="10"
            >
              {images.map((img, i) => (
                <MotionBox
                  key={img.node.childImageSharp?.fluid.src}
                  animate={{
                    width: index !== i ? '8px' : '32px',
                    height: '8px',
                  }}
                  mr="8px"
                  ml="8px"
                  background={index !== i ? 'gray.600' : 'blue.500'}
                  borderRadius="100px"
                  cursor="pointer"
                  onClick={(e) => setIndex(i)}
                />
              ))}
            </Flex>
          </Flex>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}
