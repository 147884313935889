import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Flex, Box, Grid, AspectRatio, Wrap, WrapItem } from '@chakra-ui/react'
import LightBox from './LightBox'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery<GatsbyTypes.GalleryImagesQuery>(graphql`
    query GalleryImages {
      galleryImages: allFile(
        filter: { sourceInstanceName: { eq: "gallery" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 400, height: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const [lightBoxImage, setlightBoxImage] = useState(-1)

  const clickOnImage = (
    index,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setlightBoxImage(index)
    e.preventDefault()
  }

  return (
    <Wrap justifyContent="space-between">
      {/* <Box gridArea="1 / 1 / 2 / 5" onClick={(e) => clickOnImage(0, e)}>
          <Img
            fluid={data.galleryImages.edges[0].node.childImageSharp?.fluid}
          />
        </Box> */}
      {data.galleryImages.edges.map((img, i) => (
        <WrapItem
          key={img.node.childImageSharp?.fixed?.src}
          onClick={(e) => clickOnImage(i, e)}
          cursor="pointer"
          w={['80px', '160px']}
          h={['80px', '160px']}
        >
          <Img
            style={{
              width: '100%',
              height: '100%',
            }}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
            fixed={img.node.childImageSharp?.fixed}
          />
        </WrapItem>
      ))}
      <LightBox
        isVisible={lightBoxImage > -1}
        images={data.galleryImages.edges}
        selectedImage={lightBoxImage}
        onClose={() => setlightBoxImage(-1)}
      />
    </Wrap>
  )
}

export default Image
