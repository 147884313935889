import React from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

interface PresetProps extends BoxProps {
  children: React.ReactNode
}

export default ({ children, ...restProps }: PresetProps) => {
  return (
    <Box
      mb={['16', '20', '20', '120px']}
      {...restProps}
    >
      {children}
    </Box>
  )
}
